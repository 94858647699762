import React, { useEffect } from 'react';
import { FieldRenderProps } from 'react-final-form';
import { Checkbox } from '@tcl-boron-prefabs/checkbox';
import { FormTableField } from './FormTable';
import FormError from './FormError';
import { DropdownOption } from '@tcl-argon-models/models';
import styles from './styles.module.css';

type FormMultiSelectCheckboxProps = FormTableField &
  FieldRenderProps<any> & {
    checkboxOptions: DropdownOption[];
  };

const FormMultiSelectCheckbox: React.FunctionComponent<FormMultiSelectCheckboxProps> = ({
  input,
  meta,
  checkboxOptions,
  uniqueOptionIndex,
  isError,
}) => {
  const [isSelected, setIsSelected] = React.useState<Array<string>>();

  useEffect(() => {
    if (meta.pristine) {
      setIsSelected([]);
    }
  }, [meta.pristine]);

  const toggleCheckboxList = (index: number) => {
    let newState: string[];
    const option = checkboxOptions[index];

    //clear all other fields when the unique option becomes checked
    if (uniqueOptionIndex && index === uniqueOptionIndex) {
      const uniqueOption = checkboxOptions[uniqueOptionIndex];
      if (isSelected?.includes(uniqueOption.value)) {
        newState = [];
      } else {
        newState = [uniqueOption.value];
      }
    }
    // deselect checkbox when already selected
    else if (isSelected?.includes(option.value)) {
      newState = isSelected.filter((item) => item !== option.value);
    }
    //otherwise, add selection
    else {
      newState = [...(isSelected ?? []), option.value];
      //remove the unique option when a new selection happens
      if (uniqueOptionIndex) {
        const uniqueOption = checkboxOptions[uniqueOptionIndex];
        newState = newState.filter((item) => item !== uniqueOption?.value);
      }
    }
    setIsSelected(newState);
    input.onChange(newState);
  };

  return (
    <div className={styles.fieldRoot} onBlurCapture={input.onBlur} onFocusCapture={input.onFocus}>
      {checkboxOptions.map((option, index) => {
        return (
          <Checkbox
            label={option.label}
            key={index}
            disabled={meta.submitting}
            isSelected={isSelected?.includes(option.value)}
            onChange={() => toggleCheckboxList(index)}
            classes={{
              checkboxContainer: styles.checkboxContainer,
              label: styles.multiSelectCheckboxLabel,
            }}
          />
        );
      })}
      {isError ? <FormError error={meta.error} /> : undefined}
    </div>
  );
};

export default FormMultiSelectCheckbox;
