import React from 'react';
import isEmail from 'validator/lib/isEmail';
import typography from '@tcl-boron-styles/typography/dist/index.module.css';
import {
  NAME_CHARACTER_LIMIT,
  RELATIONSHIP_TO_PATIENT_NAME_V3,
  RelationshipToPatient,
  consentToApplicationV3Fields as consentToApplicationV3FieldsBase,
  getRelationshipToPatientDropdownOptions,
} from '@tempus/patient-forms-service-shared';

import FormTable, { FormTableFieldFrontend } from '../../shared/FormTable';
import { combineFormConfigs, isValidPhoneNumber } from '../../shared/utils';
import {
  CHARACTER_LIMIT_WARNING,
  INVALID_EMAIL_WARNING,
  INVALID_PHONE_WARNING,
  REQUIRED_FIELD_WARNING,
} from '../../shared/validation-utils';

export const consentToApplicationV3FieldsFrontend: FormTableFieldFrontend[] = [
  {
    name: RELATIONSHIP_TO_PATIENT_NAME_V3,
    props: {
      placeholder: 'Select',
      dropdownOptions: getRelationshipToPatientDropdownOptions(),
    },
    validate: (value: any | undefined) => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      }
    },
  },
  {
    name: 'representativeFirstName',
    validate: (value: any | undefined) => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      } else if (String(value).length > NAME_CHARACTER_LIMIT) {
        return CHARACTER_LIMIT_WARNING;
      }
    },
    conditionalValueConfig: {
      fieldNameToCheck: RELATIONSHIP_TO_PATIENT_NAME_V3,
      valueToCheck: RelationshipToPatient.REPRESENTATIVE,
      conditionalLogic: (value: any, conditionalValue: string) => {
        return value === conditionalValue;
      },
    },
  },
  {
    name: 'representativeLastName',
    validate: (value: any | undefined) => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      } else if (String(value).length > NAME_CHARACTER_LIMIT) {
        return CHARACTER_LIMIT_WARNING;
      }
    },
    conditionalValueConfig: {
      fieldNameToCheck: RELATIONSHIP_TO_PATIENT_NAME_V3,
      valueToCheck: RelationshipToPatient.REPRESENTATIVE,
      conditionalLogic: (value: any, conditionalValue: string) => {
        return value === conditionalValue;
      },
    },
  },
  {
    name: 'representativeEmail',
    validate: (value: any | undefined) => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      } else if (!isEmail(value)) {
        return INVALID_EMAIL_WARNING;
      }
    },
    conditionalValueConfig: {
      fieldNameToCheck: RELATIONSHIP_TO_PATIENT_NAME_V3,
      valueToCheck: RelationshipToPatient.REPRESENTATIVE,
      conditionalLogic: (value: any, conditionalValue: string) => {
        return value === conditionalValue;
      },
    },
  },
  {
    name: 'representativePhone',
    props: {
      placeholder: '(XXX)XXX-XXXX',
    },
    validate: (value: any | undefined) => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      } else if (!isValidPhoneNumber(value)) {
        return INVALID_PHONE_WARNING;
      }
    },
    conditionalValueConfig: {
      fieldNameToCheck: RELATIONSHIP_TO_PATIENT_NAME_V3,
      valueToCheck: RelationshipToPatient.REPRESENTATIVE,
      conditionalLogic: (value: any, conditionalValue: string) => {
        return value === conditionalValue;
      },
    },
  },
  {
    name: 'patientConsent',
    validate: (value: any | undefined) => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      }
    },
    conditionalValueConfig: {
      fieldNameToCheck: RELATIONSHIP_TO_PATIENT_NAME_V3,
      valueToCheck: RelationshipToPatient.SELF,
      conditionalLogic: (value: any, conditionalValue: string) => {
        return value === conditionalValue;
      },
    },
  },
  {
    name: 'patientRepresentativeConsent',
    validate: (value: any | undefined) => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      }
    },
    conditionalValueConfig: {
      fieldNameToCheck: RELATIONSHIP_TO_PATIENT_NAME_V3,
      valueToCheck: RelationshipToPatient.REPRESENTATIVE,
      conditionalLogic: (value: any, conditionalValue: string) => {
        return value === conditionalValue;
      },
    },
  },
  {
    name: 'signature',
    validate: (value: any | undefined) => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      }
    },
  },
];

export const consentToApplicationV3Fields = combineFormConfigs(
  consentToApplicationV3FieldsFrontend,
  consentToApplicationV3FieldsBase,
);

const ConsentToApplicationTableV3: React.FunctionComponent = () => {
  return (
    <>
      <div className={typography.subSectionHeader}>Consent to Application</div>
      <FormTable fields={consentToApplicationV3Fields} />
    </>
  );
};

export default ConsentToApplicationTableV3;
