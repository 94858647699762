import React, { useState } from 'react';
import classNames from 'classnames';
import { Button } from '@tcl-boron-prefabs/button';
import typography from '@tcl-boron-styles/typography/dist/index.module.css';

import Logo from '../../shared/Logo';
import { NavigationFormProps } from '../../../custom-to-do-list/components/NavigatorChecklist';
import styles from '../../shared/styles.module.css';
import { useFormState, FormProps as FinalFormProps } from 'react-final-form';
import Form from '../../../forms/shared/Form';
import FormTable, { FormTableField } from '../../shared/FormTable';
import { FormTableFieldType } from '@tempus/patient-forms-service-shared';
import { REQUIRED_FIELD_WARNING } from '../../shared/validation-utils';

export const NEURO_FA_URL = 'https://www.tempus.com/access-neuro/';

export enum Division {
  Oncology = 'ONCO',
  Neurology = 'NEURO',
  Other = 'OTHER',
}

const verificationFields: FormTableField[] = [
  {
    label: 'I am completing this Financial Assistance Application for:',
    name: 'division',
    props: {
      placeholder: 'Select',
      dropdownOptions: [
        { label: 'Cancer or at Risk of Cancer (germline or hereditary risk)', value: Division.Oncology },
        { label: 'Mental Health (drug metabolism)', value: Division.Neurology },
        { label: 'Other', value: Division.Other },
      ],
    },
    type: FormTableFieldType.SINGLE_SELECT_DROPDOWN,
    validate: (value: any | undefined) => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      }
    },
  },
];

export type FAOncoNeuroSelectoFormPropsNoChild = React.PropsWithChildren<
  {
    formKey: string;
  } & Omit<NavigationFormProps, 'validatedValues'>
>;

export type FAOncoNeuroSelectoFormProps = React.PropsWithChildren<FAOncoNeuroSelectoFormPropsNoChild>;

export const FAOncoNeuroSelectorInner: React.FunctionComponent<FAOncoNeuroSelectoFormProps> = (props) => {
  const formState = useFormState();

  return (
    <div>
      <div className={classNames(typography.body, styles.screen)}>
        <div className={styles.header}>
          <div className={styles.headerLogo}>
            <Logo />
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.title}>Financial Assistance</div>
          <div className={styles.section}>
            <p>
              To help with financial concerns around any out-of-pocket expenses, Tempus and its contracted reference
              labs have each designed a Financial Assistance Program that offers predictable out-of-pocket costs, and
              helps provide access to medically necessary tests to patients who may not otherwise be able to afford
              them. Approval of the financial assistance application is based on the applicable billing laboratory.s
              financial assistance program. Your eligibility for financial assistance may vary depending on the
              applicable program.
            </p>
          </div>
          <FormTable fields={verificationFields} />
          <div className={styles.footer}>
            {props.handleNavigationBack && (
              <div className={styles.backButton}>
                <Button
                  ariaLabel="financial-assistance-back"
                  type="button"
                  buttonType="secondary"
                  onClick={props.handleNavigationBack}
                  disabled={formState.submitting}
                >
                  Back
                </Button>
              </div>
            )}
            <Button loading={formState.submitting} ariaLabel="financial-assistance-submit" type="submit">
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const FAOncoNeuroSelector: React.FunctionComponent<FAOncoNeuroSelectoFormProps> = (props) => {
  const [showSelector, setSelector] = useState(props.isInStandaloneChecklist);
  const onSubmit = React.useCallback<FinalFormProps['onSubmit']>((values) => {
    if (values.division === Division.Neurology) {
      window.open(NEURO_FA_URL, '_blank', 'noopener noreferrer');
      props.handleNavigationSubmit?.(props.formKey);
    } else {
      setSelector(false);
    }
  }, []);

  if (!showSelector) {
    return props.children as JSX.Element;
  }
  return (
    <Form onSubmit={onSubmit}>
      <FAOncoNeuroSelectorInner {...props} />
    </Form>
  );
};
