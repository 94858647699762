import React from 'react';
import typography from '@tcl-boron-styles/typography/dist/index.module.css';
import {
  EXTENUATING_CIRCUMSTANCES_NAME_V3,
  LONG_TEXT_CHARACTER_LIMIT,
  OTHER_VALUE,
  extenuatingCircumstancesV3Fields as extenuatingCircumstancesV3FieldsBase,
  getExtenuatingCircumstancesOptions,
} from '@tempus/patient-forms-service-shared';

import FormTable, { FormTableFieldFrontend } from '../../shared/FormTable';
import { combineFormConfigs } from '../../shared/utils';
import { CHARACTER_LIMIT_WARNING, REQUIRED_FIELD_WARNING } from '../../shared/validation-utils';

export const extenuatingCircumstancesFieldsV3Frontend: FormTableFieldFrontend[] = [
  {
    name: EXTENUATING_CIRCUMSTANCES_NAME_V3,
    props: {
      checkboxOptions: getExtenuatingCircumstancesOptions(),
      uniqueOptionIndex: 8, //index for 'none'
    },
    validate: (value: any | undefined) => {
      if (!value || !value.length) {
        return REQUIRED_FIELD_WARNING;
      }
    },
  },
  {
    name: 'otherExtenuatingCircumstances',
    props: {
      placeholder: 'Enter',
      showDynamicField: true,
    },
    validate: (value: any | undefined) => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      } else if (String(value).length > LONG_TEXT_CHARACTER_LIMIT) {
        return CHARACTER_LIMIT_WARNING;
      }
    },
    conditionalValueConfig: {
      fieldNameToCheck: EXTENUATING_CIRCUMSTANCES_NAME_V3,
      valueToCheck: OTHER_VALUE,
      conditionalLogic: (value: any, conditionalValue: string) => {
        if (!value) {
          return false;
        }
        return value.includes(conditionalValue);
      },
    },
  },
  {
    name: 'additionalInformation',
    validate: (value: any | undefined) => {
      if (value && String(value).length > LONG_TEXT_CHARACTER_LIMIT) {
        return CHARACTER_LIMIT_WARNING;
      }
    },
  },
];

export const extenuatingCircumstancesV3Fields = combineFormConfigs(
  extenuatingCircumstancesFieldsV3Frontend,
  extenuatingCircumstancesV3FieldsBase,
);

const OrderingPhysicianV3Table: React.FunctionComponent = () => {
  return (
    <>
      <div className={typography.subSectionHeader}>Extenuating Circumstances</div>
      <FormTable fields={extenuatingCircumstancesV3Fields} />
    </>
  );
};

export default OrderingPhysicianV3Table;
