import React from 'react';
import { ConsentToTestGenericV2, CONSENT_TO_TEST_V2_KEY } from '../../forms/consent-to-test/generic/v2';
import { ConsentToTestNewYorkV2, CONSENT_TO_TEST_NY_V2_KEY } from '../../forms/consent-to-test/new-york/v2';
import { FinancialAssistanceV2, FINANCIAL_ASSISTANCE_V2_KEY } from '../../forms/financial-assistance/v2';
import { HipaaAuthorizationV2, HIPAA_AUTHORIZATION_V2_KEY } from '../../forms/hipaa-authorization/v2';
import { HipaaAuthorizationV3, HIPAA_AUTHORIZATION_V3_KEY } from '../../forms/hipaa-authorization/v3';
import { ValidatedValues } from './Verification';
import { CONSENT_TO_TEST_NEW_YORK_V3_KEY, CONSENT_TO_TEST_V3_KEY } from '@tempus/patient-forms-service-shared';
import { ConsentToTestGenericV3 } from '../../forms/consent-to-test/generic/v3';
import { ConsentToTestNewYorkV3 } from '../../forms/consent-to-test/new-york/v3';
import { FINANCIAL_ASSISTANCE_V3_KEY, FinancialAssistanceV3 } from '../../forms/financial-assistance/v3';

const NavigatorFormElement = (
  formKey: string,
  submitHandler: (key: string) => void,
  backHandler: () => void,
  isInStandaloneChecklist: boolean,
  validatedValues?: ValidatedValues,
): React.ReactElement => {
  switch (formKey) {
    case FINANCIAL_ASSISTANCE_V2_KEY: {
      return (
        <FinancialAssistanceV2
          handleNavigationBack={backHandler}
          handleNavigationSubmit={submitHandler}
          validatedValues={validatedValues}
          isInStandaloneChecklist={isInStandaloneChecklist}
        />
      );
    }
    case FINANCIAL_ASSISTANCE_V3_KEY: {
      return (
        <FinancialAssistanceV3
          handleNavigationBack={backHandler}
          handleNavigationSubmit={submitHandler}
          validatedValues={validatedValues}
          isInStandaloneChecklist={isInStandaloneChecklist}
        />
      );
    }
    case HIPAA_AUTHORIZATION_V2_KEY: {
      return (
        <HipaaAuthorizationV2
          handleNavigationBack={backHandler}
          handleNavigationSubmit={submitHandler}
          validatedValues={validatedValues}
          isInStandaloneChecklist={isInStandaloneChecklist}
        />
      );
    }
    case HIPAA_AUTHORIZATION_V3_KEY: {
      return (
        <HipaaAuthorizationV3
          handleNavigationBack={backHandler}
          handleNavigationSubmit={submitHandler}
          validatedValues={validatedValues}
          isInStandaloneChecklist={isInStandaloneChecklist}
        />
      );
    }
    case CONSENT_TO_TEST_NY_V2_KEY: {
      return (
        <ConsentToTestNewYorkV2
          handleNavigationBack={backHandler}
          handleNavigationSubmit={submitHandler}
          validatedValues={validatedValues}
          isInStandaloneChecklist={isInStandaloneChecklist}
        />
      );
    }
    case CONSENT_TO_TEST_V2_KEY: {
      return (
        <ConsentToTestGenericV2
          handleNavigationBack={backHandler}
          handleNavigationSubmit={submitHandler}
          validatedValues={validatedValues}
          isInStandaloneChecklist={isInStandaloneChecklist}
        />
      );
    }
    case CONSENT_TO_TEST_V3_KEY: {
      return (
        <ConsentToTestGenericV3
          handleNavigationBack={backHandler}
          handleNavigationSubmit={submitHandler}
          validatedValues={validatedValues}
          isInStandaloneChecklist={isInStandaloneChecklist}
        />
      );
    }
    case CONSENT_TO_TEST_NEW_YORK_V3_KEY: {
      return (
        <ConsentToTestNewYorkV3
          handleNavigationBack={backHandler}
          handleNavigationSubmit={submitHandler}
          validatedValues={validatedValues}
          isInStandaloneChecklist={isInStandaloneChecklist}
        />
      );
    }
    default: {
      return <></>;
    }
  }
};

export default NavigatorFormElement;
