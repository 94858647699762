import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { TextArea } from '@tcl-boron-prefabs/text-area';
import styles from './styles.module.css';
import { FormTableField } from './FormTable';
import classNames from 'classnames';
import FormError from './FormError';

type FormTextAreaProps = FormTableField &
  FieldRenderProps<any> & {
    placeholder?: string;
  };

const FormTextArea: React.FunctionComponent<FormTextAreaProps> = ({ input, meta, placeholder, isError }) => {
  return (
    <div className={classNames(styles.fieldRoot, styles.tableRowContentFocusArea)}>
      <div className={isError ? styles.textAreaInputRootError : styles.textAreaInputRoot}>
        <TextArea
          {...input}
          value={input.value ?? ''}
          hideLabel
          disabled={meta.submitting}
          classes={{
            textArea: classNames(styles.textAreaInput, styles.inputWrapper),
          }}
          label={input.name}
          placeholder={placeholder}
          textAreaType={'secondary'}
        />
      </div>
      {isError ? <FormError error={meta.error} /> : undefined}
    </div>
  );
};

export default FormTextArea;
