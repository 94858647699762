import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import { FORM_ERROR } from 'final-form';
import moment from 'moment';
import { Field, useFormState } from 'react-final-form';
import { Prompt } from 'react-router-dom';
import { Button } from '@tcl-boron-prefabs/button';
import typography from '@tcl-boron-styles/typography/dist/index.module.css';
import {
  CONSENT_TO_TEST_NEW_YORK_V2_KEY,
  CONSENT_TO_TEST_NEW_YORK_V2_TITLE,
  ConsentToTestNewYorkV2CheckboxProps,
  ConsentToTestNewYorkV2Content,
  FULL_NAME_CHARACTER_LIMIT,
  consentToTestNewYorkV2TableFields as consentToTestNewYorkV2TableFieldsBase,
} from '@tempus/patient-forms-service-shared';
import { NavigationFormProps } from '../../../../custom-to-do-list/components/NavigatorChecklist';
import {
  VALIDATED_PATIENT_DATE_OF_BIRTH_PROPERTY,
  VALIDATED_PATIENT_FIRST_NAME_PROPERTY,
  VALIDATED_PATIENT_LAST_NAME_PROPERTY,
} from '../../../../custom-to-do-list/components/Verification';
import { FormProps, submitFormGeneric } from '../../../forms';
import Form from '../../../shared/Form';
import FormAlert, { AlertStatus, SUBMIT_SUCCESS_MESSAGE, SUBMIT_SUCCESS_TITLE } from '../../../shared/FormAlert';
import FormBannerError from '../../../shared/FormBannerError';
import FormCheckbox from '../../../shared/FormCheckbox';
import FormTable, { FormTableFieldFrontend, ValidatedValueConfig } from '../../../shared/FormTable';
import Logo from '../../../shared/Logo';
import {
  LEAVE_FORM_WARNING,
  combineFormConfigs,
  isValidDateOfBirth,
  setInitialFieldsWithValidatedValues,
  setReadOnlyValidatedValuesOnSubmit,
} from '../../../shared/utils';
import {
  CHARACTER_LIMIT_WARNING,
  INVALID_DATE_OF_BIRTH_WARNING,
  REQUIRED_FIELD_WARNING,
} from '../../../shared/validation-utils';

import styles from '../../../shared/styles.module.css';
import _ from 'lodash';
import { useQuery } from '../../../../../api/hooks';

const DATE_FILTER_MIN = moment('1900-01-01T06:00:00.000Z').toDate();
const now = moment().toDate();

export const CONSENT_TO_TEST_NY_V2_KEY = CONSENT_TO_TEST_NEW_YORK_V2_KEY;

const consentToTestNewYorkV2FieldsFrontend: FormTableFieldFrontend[] = [
  {
    name: 'patientName',
    props: {
      placeholder: 'Enter',
    },
    validate: (value: any | undefined) => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      } else if (String(value).length > FULL_NAME_CHARACTER_LIMIT) {
        return CHARACTER_LIMIT_WARNING;
      }
    },
    validatedValueConfig: {
      validatedValueNames: [VALIDATED_PATIENT_FIRST_NAME_PROPERTY, VALIDATED_PATIENT_LAST_NAME_PROPERTY],
      readOnly: true,
      concatenator: ' ',
    } as ValidatedValueConfig,
  },
  {
    name: 'dob',
    props: {
      placeholder: 'MM/DD/YY',
      minDate: DATE_FILTER_MIN,
      maxDate: now,
    },
    validate: (value: any | undefined) => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      } else if (!isValidDateOfBirth(value)) {
        return INVALID_DATE_OF_BIRTH_WARNING;
      }
    },
    validatedValueConfig: {
      validatedValueNames: [VALIDATED_PATIENT_DATE_OF_BIRTH_PROPERTY],
      readOnly: true,
    } as ValidatedValueConfig,
  },
  {
    name: 'signature',
    validate: (value: any | undefined) => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      }
    },
  },
];

const consentToTestNewYorkV2TableFields = combineFormConfigs(
  consentToTestNewYorkV2FieldsFrontend,
  consentToTestNewYorkV2TableFieldsBase,
);

const ConsentToTestNewYorkV2Checkbox: React.FunctionComponent<ConsentToTestNewYorkV2CheckboxProps> = (props) => {
  return (
    <div className={styles.checkbox}>
      <Field
        name={props.name}
        render={(renderProps) => {
          return (
            <FormCheckbox
              label={props.label}
              checkboxLabel={props.checkboxLabel}
              name={props.label}
              type={props.type}
              {...renderProps}
            />
          );
        }}
      />
    </div>
  );
};

const ConsentToTestNewYork: React.FunctionComponent<FormProps> = (props) => {
  const formState = useFormState();
  const clonedFields = useMemo(() => _.cloneDeep(consentToTestNewYorkV2TableFields), []);

  if (props.validatedValues) {
    setInitialFieldsWithValidatedValues(clonedFields, props.validatedValues);
  }

  return (
    <div>
      {props.handleNavigationBack && (
        <Prompt when={formState.dirty && !formState.submitSucceeded} message={LEAVE_FORM_WARNING} />
      )}
      <div className={classNames(typography.body, styles.screen)}>
        <div className={styles.header}>
          <div className={styles.headerLogo}>
            <Logo />
          </div>
        </div>
        <div className={styles.content}>
          <p className={styles.title}>{CONSENT_TO_TEST_NEW_YORK_V2_TITLE}</p>
          {formState.submitFailed ? <FormBannerError /> : undefined}
          {formState.submitSucceeded && !props.handleNavigationSubmit ? (
            props.showAlert ? (
              <FormAlert
                setShowAlert={props.setShowAlert}
                message={SUBMIT_SUCCESS_MESSAGE}
                title={SUBMIT_SUCCESS_TITLE}
                status={AlertStatus.SUCCESS}
              />
            ) : undefined
          ) : undefined}
          <ConsentToTestNewYorkV2Content Checkbox={ConsentToTestNewYorkV2Checkbox} />
          <FormTable fields={clonedFields} />
          <div className={styles.footer}>
            {props.handleNavigationBack && (
              <div className={styles.backButton}>
                <Button
                  ariaLabel="onco-consent-to-test-ny-back"
                  type="button"
                  buttonType="secondary"
                  onClick={props.handleNavigationBack}
                  disabled={formState.submitting}
                >
                  Back
                </Button>
              </div>
            )}
            <Button loading={formState.submitting} ariaLabel="onco-consent-to-test-ny-submit" type="submit">
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const ConsentToTestNewYorkV2Wrapper: React.FunctionComponent<NavigationFormProps> = (props) => {
  const selectedId = props.handleNavigationSubmit ? useQuery('orderId') : null;
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);

  const onSubmit = React.useCallback(async (values: any) => {
    try {
      if (props.validatedValues) {
        setReadOnlyValidatedValuesOnSubmit(values, consentToTestNewYorkV2TableFields, props.validatedValues);
      }

      await submitFormGeneric(CONSENT_TO_TEST_NY_V2_KEY, selectedId, values);
      if (props.handleNavigationSubmit) {
        props.handleNavigationSubmit(CONSENT_TO_TEST_NY_V2_KEY);
      } else {
        setShowSuccessAlert(true);
      }
    } catch (e) {
      setShowSuccessAlert(false);
      return { [FORM_ERROR]: 'Submission Failed' };
    }
  }, []);

  return (
    <Form onSubmit={onSubmit}>
      <ConsentToTestNewYork {...props} showAlert={showSuccessAlert} setShowAlert={setShowSuccessAlert} />
    </Form>
  );
};

export default ConsentToTestNewYorkV2Wrapper;
