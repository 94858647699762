import React, { useMemo } from 'react';
import moment from 'moment';
import isEmail from 'validator/lib/isEmail';
import isNumeric from 'validator/lib/isNumeric';
import typography from '@tcl-boron-styles/typography/dist/index.module.css';
import {
  LONG_TEXT_CHARACTER_LIMIT,
  NAME_CHARACTER_LIMIT,
  SHORT_TEXT_CHARACTER_LIMIT,
  getAllStatesDropdownOptionsV2,
  getContactMethodDropdownOptionsV2,
  getNumberFamilyMembersDropdownOptionsV2,
  getSexDropdownOptionsV2,
  getYesNoDropdownOptionsV2,
  patientInformationV2Fields as patientInformationV2FieldsBase,
} from '@tempus/patient-forms-service-shared';

import {
  VALIDATED_PATIENT_DATE_OF_BIRTH_PROPERTY,
  VALIDATED_PATIENT_FIRST_NAME_PROPERTY,
  VALIDATED_PATIENT_LAST_NAME_PROPERTY,
} from '../../../custom-to-do-list/components/Verification';
import { FormTableProps } from '../../forms';
import FormTable, { FormTableFieldFrontend, ValidatedValueConfig } from '../../shared/FormTable';
import {
  DATE_FILTER_MIN,
  combineFormConfigs,
  isValidDateOfBirth,
  isValidPhoneNumber,
  isValidUSZip,
  setInitialFieldsWithValidatedValues,
} from '../../shared/utils';
import {
  CHARACTER_LIMIT_WARNING,
  INVALID_DATE_OF_BIRTH_WARNING,
  INVALID_EMAIL_WARNING,
  INVALID_NUMBER_WARNING,
  INVALID_PHONE_WARNING,
  INVALID_ZIP_WARNING,
  REQUIRED_FIELD_WARNING,
} from '../../shared/validation-utils';
import _ from 'lodash';

export const patientInformationFieldsV2Frontend: FormTableFieldFrontend[] = [
  {
    name: 'patientFirstName',
    props: {
      placeholder: 'Enter',
    },
    validate: (value: any | undefined) => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      } else if (String(value).length > NAME_CHARACTER_LIMIT) {
        return CHARACTER_LIMIT_WARNING;
      }
    },
    validatedValueConfig: {
      validatedValueNames: [VALIDATED_PATIENT_FIRST_NAME_PROPERTY],
      readOnly: true,
    } as ValidatedValueConfig,
  },
  {
    name: 'patientLastName',
    props: {
      placeholder: 'Enter',
    },
    validate: (value: any | undefined) => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      } else if (String(value).length > NAME_CHARACTER_LIMIT) {
        return CHARACTER_LIMIT_WARNING;
      }
    },
    validatedValueConfig: {
      validatedValueNames: [VALIDATED_PATIENT_LAST_NAME_PROPERTY],
      readOnly: true,
    } as ValidatedValueConfig,
  },
  {
    name: 'dateOfBirth',
    props: {
      placeholder: 'MM/DD/YY',
      minDate: DATE_FILTER_MIN,
      maxDate: moment().toDate(),
    },
    validate: (value: any | undefined) => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      } else if (!isValidDateOfBirth(value)) {
        return INVALID_DATE_OF_BIRTH_WARNING;
      }
    },
    validatedValueConfig: {
      validatedValueNames: [VALIDATED_PATIENT_DATE_OF_BIRTH_PROPERTY],
      readOnly: true,
    } as ValidatedValueConfig,
  },
  {
    name: 'sex',
    props: {
      placeholder: 'Select',
      dropdownOptions: getSexDropdownOptionsV2(),
    },
    validate: (value: any | undefined) => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      }
    },
  },
  {
    name: 'address',
    props: {
      placeholder: 'Street address',
    },
    validate: (value: any | undefined) => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      } else if (String(value).length > LONG_TEXT_CHARACTER_LIMIT) {
        return CHARACTER_LIMIT_WARNING;
      }
    },
  },
  {
    name: 'addressLineTwo',
    props: {
      placeholder: 'Street address line 2',
    },
    validate: (value: any | undefined) => {
      if (value && String(value).length > LONG_TEXT_CHARACTER_LIMIT) {
        return CHARACTER_LIMIT_WARNING;
      }
    },
  },
  {
    name: 'city',
    props: {
      placeholder: 'Enter',
    },
    validate: (value: any | undefined) => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      } else if (String(value).length > SHORT_TEXT_CHARACTER_LIMIT) {
        return CHARACTER_LIMIT_WARNING;
      }
    },
  },
  {
    name: 'state',
    props: {
      placeholder: 'Select',
      dropdownOptions: getAllStatesDropdownOptionsV2(),
    },
    validate: (value: any | undefined) => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      }
    },
  },
  {
    name: 'zipCode',
    props: {
      placeholder: 'Enter',
    },
    validate: (value: any | undefined) => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      } else if (!isValidUSZip(value)) {
        return INVALID_ZIP_WARNING;
      }
    },
  },
  {
    name: 'healthInsurance',
    props: {
      placeholder: 'Select',
      dropdownOptions: getYesNoDropdownOptionsV2(),
    },
    validate: (value: any | undefined) => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      }
    },
  },
  {
    name: 'healthInsuranceToCoverTest',
    props: {
      placeholder: 'Select',
      dropdownOptions: getYesNoDropdownOptionsV2(),
    },
    validate: (value: any | undefined) => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      }
    },
  },
  {
    name: 'contactMethod',
    props: {
      placeholder: 'Select',
      dropdownOptions: getContactMethodDropdownOptionsV2(),
    },
    validate: (value: any | undefined) => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      }
    },
  },
  {
    name: 'emailAddress',
    props: {
      placeholder: 'Example@email.com',
    },
    validate: (value: any | undefined, allValues: any) => {
      if (!value && (!allValues.contactMethod || allValues.contactMethod === 'EMAIL')) {
        return REQUIRED_FIELD_WARNING;
      } else if (value && !isEmail(value)) {
        return INVALID_EMAIL_WARNING;
      }
    },
  },
  {
    name: 'phoneNumber',
    props: {
      placeholder: '(XXX)XXX-XXXX',
    },
    validate: (value: any | undefined, allValues: any) => {
      if (!value && (!allValues.contactMethod || allValues.contactMethod === 'PHONE')) {
        return REQUIRED_FIELD_WARNING;
      } else if (value && !isValidPhoneNumber(value)) {
        return INVALID_PHONE_WARNING;
      }
    },
  },
  {
    name: 'grossIncome',
    props: {
      placeholder: 'Enter',
      type: 'number',
    },
    validate: (value: any | undefined) => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      } else if (!isNumeric(value, { no_symbols: true })) {
        return INVALID_NUMBER_WARNING;
      }
    },
  },
  {
    name: 'numberFamilyMembers',
    props: {
      placeholder: 'Select',
      dropdownOptions: getNumberFamilyMembersDropdownOptionsV2(),
    },
    validate: (value: any | undefined) => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      }
    },
  },
];

export const patientInformationV2Fields = combineFormConfigs(
  patientInformationFieldsV2Frontend,
  patientInformationV2FieldsBase,
);

const PatientInformationTable: React.FunctionComponent<FormTableProps> = (props) => {
  const clonedFields = useMemo(() => _.cloneDeep(patientInformationV2Fields), []);
  if (props.validatedValues) {
    setInitialFieldsWithValidatedValues(clonedFields, props.validatedValues);
  }

  return (
    <>
      <div className={typography.subSectionHeader}>Patient Information</div>
      <FormTable fields={clonedFields} />
    </>
  );
};

export default PatientInformationTable;
