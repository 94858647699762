import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import { FORM_ERROR } from 'final-form';
import { useFormState } from 'react-final-form';
import { Prompt } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';
import { Button } from '@tcl-boron-prefabs/button';
import typography from '@tcl-boron-styles/typography/dist/index.module.css';
import {
  FULL_NAME_CHARACTER_LIMIT,
  HIPAA_AUTHORIZATION_V2_KEY,
  HIPAA_AUTHORIZATION_V2_TITLE,
  HipaaAuthorizationV2Content,
  hipaaAuthorizationV2Fields as hipaaAuthorizationV2FieldsBase,
} from '@tempus/patient-forms-service-shared';
import { NavigationFormProps } from '../../../custom-to-do-list/components/NavigatorChecklist';
import {
  VALIDATED_PATIENT_FIRST_NAME_PROPERTY,
  VALIDATED_PATIENT_LAST_NAME_PROPERTY,
} from '../../../custom-to-do-list/components/Verification';
import { FormProps, submitFormGeneric } from '../../forms';
import Form from '../../shared/Form';
import FormAlertSuccess, { AlertStatus, SUBMIT_SUCCESS_MESSAGE, SUBMIT_SUCCESS_TITLE } from '../../shared/FormAlert';
import FormBannerError from '../../shared/FormBannerError';
import FormTable, { FormTableFieldFrontend, ValidatedValueConfig } from '../../shared/FormTable';
import Logo from '../../shared/Logo';
import {
  LEAVE_FORM_WARNING,
  combineFormConfigs,
  setInitialFieldsWithValidatedValues,
  setReadOnlyValidatedValuesOnSubmit,
} from '../../shared/utils';
import { INVALID_EMAIL_WARNING, REQUIRED_FIELD_WARNING, getCharacterLimitWarning } from '../../shared/validation-utils';

import styles from '../../shared/styles.module.css';
import _ from 'lodash';
import { useQuery } from '../../../../api/hooks';

export { HIPAA_AUTHORIZATION_V2_KEY };

const hipaaAuthorizationV2FieldsFrontend: FormTableFieldFrontend[] = [
  {
    name: 'patientName',
    props: {
      placeholder: 'Enter',
    },
    validate: (value: any | undefined) => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      } else if (String(value).length > FULL_NAME_CHARACTER_LIMIT) {
        return getCharacterLimitWarning(String(value).length, FULL_NAME_CHARACTER_LIMIT);
      }
    },
    validatedValueConfig: {
      validatedValueNames: [VALIDATED_PATIENT_FIRST_NAME_PROPERTY, VALIDATED_PATIENT_LAST_NAME_PROPERTY],
      readOnly: true,
      concatenator: ' ',
    } as ValidatedValueConfig,
  },
  {
    name: 'patientEmail',
    props: {
      placeholder: 'Example@email.com',
    },
    validate: (value: any | undefined) => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      } else if (!isEmail(value)) {
        return INVALID_EMAIL_WARNING;
      }
    },
  },
  {
    name: 'signature',
    validate: (value: any | undefined) => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      }
    },
  },
];

const hipaaAuthorizationV2Fields = combineFormConfigs(
  hipaaAuthorizationV2FieldsFrontend,
  hipaaAuthorizationV2FieldsBase,
);

const HipaaAuthorizationV2: React.FunctionComponent<FormProps> = (props) => {
  const formState = useFormState();
  const clonedFields = useMemo(() => _.cloneDeep(hipaaAuthorizationV2Fields), []);

  if (props.validatedValues) {
    setInitialFieldsWithValidatedValues(clonedFields, props.validatedValues);
  }

  return (
    <div>
      {props.handleNavigationBack && (
        <Prompt when={formState.dirty && !formState.submitSucceeded} message={LEAVE_FORM_WARNING} />
      )}
      <div className={classNames(typography.body, styles.screen)}>
        <div className={styles.header}>
          <div className={styles.headerLogo}>
            <Logo />
          </div>
        </div>
        <div className={styles.content}>
          <p className={styles.title}>{HIPAA_AUTHORIZATION_V2_TITLE}</p>
          {formState.submitFailed ? <FormBannerError /> : undefined}
          {formState.submitSucceeded && !props.handleNavigationSubmit ? (
            props.showAlert ? (
              <FormAlertSuccess
                setShowAlert={props.setShowAlert}
                message={SUBMIT_SUCCESS_MESSAGE}
                title={SUBMIT_SUCCESS_TITLE}
                status={AlertStatus.SUCCESS}
              />
            ) : undefined
          ) : undefined}
          <HipaaAuthorizationV2Content />
          <FormTable fields={clonedFields} />
          <div className={styles.footer}>
            {props.handleNavigationBack && (
              <div className={styles.backButton}>
                <Button
                  ariaLabel="onco-hipaa-auth-back"
                  type="button"
                  buttonType="secondary"
                  onClick={props.handleNavigationBack}
                  disabled={formState.submitting}
                >
                  Back
                </Button>
              </div>
            )}
            <Button loading={formState.submitting} ariaLabel="onco-hipaa-auth-submit" type="submit">
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const HipaaAuthorizationV2Wrapper: React.FunctionComponent<NavigationFormProps> = (props) => {
  const selectedId = props.handleNavigationSubmit ? useQuery('orderId') : null;
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);

  const onSubmit = React.useCallback(async (values: any) => {
    try {
      if (props.validatedValues) {
        setReadOnlyValidatedValuesOnSubmit(values, hipaaAuthorizationV2Fields, props.validatedValues);
      }
      await submitFormGeneric(HIPAA_AUTHORIZATION_V2_KEY, selectedId, values);
      if (props.handleNavigationSubmit) {
        props.handleNavigationSubmit(HIPAA_AUTHORIZATION_V2_KEY);
      } else {
        setShowSuccessAlert(true);
      }
    } catch (e) {
      setShowSuccessAlert(false);
      return { [FORM_ERROR]: 'Submission Failed' };
    }
  }, []);

  return (
    <Form onSubmit={onSubmit}>
      <HipaaAuthorizationV2 showAlert={showSuccessAlert} setShowAlert={setShowSuccessAlert} {...props} />
    </Form>
  );
};

export default HipaaAuthorizationV2Wrapper;
