import { GrayscalePalette, ActionPalette } from '@tcl-boron-colors/colors';

const datePickerStyles = {
  datePicker: {
    actions: {
      width: '276px',
      height: '55px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      '& button:nth-of-type(1)': {
        backgroundColor: GrayscalePalette[100],
        '&:hover': {
          color: GrayscalePalette[100],
        },
        '&:active': {
          color: ActionPalette[600],
          backgroundColor: GrayscalePalette[300],
          borderColor: ActionPalette[600],
        },
      },
      '& button': {
        padding: '0 24px',
        margin: '0 5px',
        cursor: 'pointer',
        height: '32px',
        outline: 'none',
        position: 'relative',
        fontSize: '14px',
        minWidth: '100px',
        fontFamily: 'inherit',
        fontWeight: 'bold',
        lineHeight: '16px',
        borderColor: ActionPalette[600],
        borderStyle: 'solid',
        borderWidth: '2px',
        borderRadius: '25px',
        textTransform: 'none',
        textDecoration: 'none',
        backgroundColor: ActionPalette[600],
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: GrayscalePalette[500],
          borderColor: GrayscalePalette[500],
        },
        '&:active': {
          color: GrayscalePalette[400],
          backgroundColor: ActionPalette[600],
          borderColor: ActionPalette[600],
        },
      },
    },
  },
}

export default datePickerStyles;
