import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { GrayscalePalette } from '@tcl-boron-colors/colors';

import FormError from './FormError';
import FormSignatureCanvas from './FormSignatureCanvas';
import { FormTableField } from './FormTable';

import styles from './styles.module.css';

type FormSignatureProps = FormTableField & FieldRenderProps<any>;

const FormSignature: React.FunctionComponent<FormSignatureProps> = ({ input, meta, isError }) => {
  return (
    <div className={styles.fieldRoot}>
      <div
        className={styles.signatureBox}
        style={{
          borderColor: GrayscalePalette[300],
        }}
      >
        <div
          className={styles.signatureInnerBox}
          style={{
            borderColor: GrayscalePalette[300],
          }}
        >
          <FormSignatureCanvas meta={meta} {...input} />
        </div>
      </div>
      {isError ? <FormError error={meta.error} /> : undefined}
    </div>
  );
};

export default FormSignature;
