import moment from 'moment';
import React, { useMemo, useState } from 'react';
import classNames from 'classnames';
import { FORM_ERROR, FormApi } from 'final-form';
import { useFormState } from 'react-final-form';
import { Prompt } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';
import { Button } from '@tcl-boron-prefabs/button';
import typography from '@tcl-boron-styles/typography/dist/index.module.css';
import {
  NAME_CHARACTER_LIMIT,
  getContactMethodDropdownOptions,
  HIPAA_AUTHORIZATION_V3_KEY,
  HIPAA_AUTHORIZATION_V3_TITLE,
  HipaaAuthorizationV3Content,
  hipaaAuthorizationV3Fields as hipaaAuthorizationV3FieldsBase,
  IS_LEGALLY_AUTHORIZED_REPRESENTATIVE_OPTIONS,
  TEXT_FIELD_CHARACTER_LIMIT,
} from '@tempus/patient-forms-service-shared';
import { NavigationFormProps } from '../../../custom-to-do-list/components/NavigatorChecklist';
import {
  VALIDATED_PATIENT_DATE_OF_BIRTH_PROPERTY,
  VALIDATED_PATIENT_FIRST_NAME_PROPERTY,
  VALIDATED_PATIENT_LAST_NAME_PROPERTY,
} from '../../../custom-to-do-list/components/Verification';
import { FormProps, submitFormGeneric } from '../../forms';
import Form from '../../shared/Form';
import FormAlert, { AlertStatus, SUBMIT_SUCCESS_MESSAGE, SUBMIT_SUCCESS_TITLE } from '../../shared/FormAlert';
import FormBannerError from '../../shared/FormBannerError';
import FormTable, { FormTableFieldFrontend, ValidatedValueConfig } from '../../shared/FormTable';
import Logo from '../../shared/Logo';
import {
  DATE_FILTER_MIN,
  LEAVE_FORM_WARNING,
  combineFormConfigs,
  isValidDateOfBirth,
  isValidPhoneNumber,
  setInitialFieldsWithValidatedValues,
  setReadOnlyValidatedValuesOnSubmit,
} from '../../shared/utils';
import {
  INVALID_DATE_OF_BIRTH_WARNING,
  INVALID_EMAIL_WARNING,
  INVALID_PHONE_WARNING,
  REQUIRED_FIELD_WARNING,
  getCharacterLimitWarning,
} from '../../shared/validation-utils';

import styles from '../../shared/styles.module.css';
import _ from 'lodash';
import { useQuery } from '../../../../api/hooks';

export { HIPAA_AUTHORIZATION_V3_KEY };

enum OPTION {
  YES = 'YES',
  NO = 'NO',
}

const hipaaAuthorizationV3FieldsFrontend: FormTableFieldFrontend[] = [
  {
    name: 'isLegallyAuthorizedRepresentative',
    props: {
      placeholder: 'Select',
      dropdownOptions: IS_LEGALLY_AUTHORIZED_REPRESENTATIVE_OPTIONS,
    },
    validate: (value: any | undefined): string | undefined => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      }
    },
  },
  {
    conditionalValueConfig: {
      fieldNameToCheck: 'isLegallyAuthorizedRepresentative',
      valueToCheck: OPTION.YES,
      conditionalLogic: (value: any, conditionalValue: string) => {
        return value === conditionalValue;
      },
    },
    name: 'legallyAuthorizedRepresentativeFirstName',
    validate: (value: any | undefined): string | undefined => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      } else if (String(value).length > NAME_CHARACTER_LIMIT) {
        return getCharacterLimitWarning(String(value).length, NAME_CHARACTER_LIMIT);
      }
    },
  },
  {
    conditionalValueConfig: {
      fieldNameToCheck: 'isLegallyAuthorizedRepresentative',
      valueToCheck: OPTION.YES,
      conditionalLogic: (value: any, conditionalValue: string) => {
        return value === conditionalValue;
      },
    },
    name: 'legallyAuthorizedRepresentativeLastName',
    validate: (value: any | undefined): string | undefined => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      } else if (String(value).length > NAME_CHARACTER_LIMIT) {
        return getCharacterLimitWarning(String(value).length, NAME_CHARACTER_LIMIT);
      }
    },
  },
  {
    conditionalValueConfig: {
      fieldNameToCheck: 'isLegallyAuthorizedRepresentative',
      valueToCheck: OPTION.YES,
      conditionalLogic: (value: any, conditionalValue: string) => {
        return value === conditionalValue;
      },
    },
    name: 'legallyAuthorizedRepresentativeRelationshipToPatient',
    validate: (value: any | undefined): string | undefined => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      } else if (String(value).length > TEXT_FIELD_CHARACTER_LIMIT) {
        return getCharacterLimitWarning(String(value).length, TEXT_FIELD_CHARACTER_LIMIT);
      }
    },
  },
  {
    conditionalValueConfig: {
      fieldNameToCheck: 'isLegallyAuthorizedRepresentative',
      valueToCheck: OPTION.YES,
      conditionalLogic: (value: any, conditionalValue: string) => {
        return value === conditionalValue;
      },
    },
    name: 'legallyAuthorizedRepresentativeConsent',
    validate: (value: any | undefined): string | undefined => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      }
    },
  },
  {
    name: 'patientFirstName',
    conditionalValueConfig: {
      fieldNameToCheck: 'isLegallyAuthorizedRepresentative',
      valueToCheck: '',
      conditionalLogic: (value: any) => {
        return !!value;
      },
    },
    props: {
      placeholder: 'Enter',
    },
    validate: (value: any | undefined) => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      } else if (String(value).length > NAME_CHARACTER_LIMIT) {
        return getCharacterLimitWarning(String(value).length, NAME_CHARACTER_LIMIT);
      }
    },
    validatedValueConfig: {
      validatedValueNames: [VALIDATED_PATIENT_FIRST_NAME_PROPERTY],
      readOnly: true,
      concatenator: ' ',
    } as ValidatedValueConfig,
  },
  {
    name: 'patientLastName',
    conditionalValueConfig: {
      fieldNameToCheck: 'isLegallyAuthorizedRepresentative',
      valueToCheck: '',
      conditionalLogic: (value: any) => {
        return !!value;
      },
    },
    props: {
      placeholder: 'Enter',
    },
    validate: (value: any | undefined) => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      } else if (String(value).length > NAME_CHARACTER_LIMIT) {
        return getCharacterLimitWarning(String(value).length, NAME_CHARACTER_LIMIT);
      }
    },
    validatedValueConfig: {
      validatedValueNames: [VALIDATED_PATIENT_LAST_NAME_PROPERTY],
      readOnly: true,
      concatenator: ' ',
    } as ValidatedValueConfig,
  },
  {
    name: 'patientDateOfBirth',
    conditionalValueConfig: {
      fieldNameToCheck: 'isLegallyAuthorizedRepresentative',
      valueToCheck: '',
      conditionalLogic: (value: any) => {
        return !!value;
      },
    },
    props: {
      placeholder: 'MM/DD/YY',
      minDate: DATE_FILTER_MIN,
      maxDate: moment().toDate(),
    },
    validate: (value: any | undefined): string | undefined => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      } else if (!isValidDateOfBirth(value)) {
        return INVALID_DATE_OF_BIRTH_WARNING;
      }
    },
    validatedValueConfig: {
      validatedValueNames: [VALIDATED_PATIENT_DATE_OF_BIRTH_PROPERTY],
      readOnly: true,
    } as ValidatedValueConfig,
  },
  {
    name: 'contactMethod',
    conditionalValueConfig: {
      fieldNameToCheck: 'isLegallyAuthorizedRepresentative',
      valueToCheck: '',
      conditionalLogic: (value: any) => {
        return !!value;
      },
    },
    props: {
      placeholder: 'Select',
      dropdownOptions: getContactMethodDropdownOptions(),
    },
    validate: (value: any | undefined) => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      }
    },
  },
  {
    name: 'patientEmail',
    conditionalValueConfig: {
      fieldNameToCheck: 'isLegallyAuthorizedRepresentative',
      valueToCheck: '',
      conditionalLogic: (value: any) => {
        return !!value;
      },
    },
    props: {
      placeholder: 'Example@email.com',
    },
    validate: (value: any | undefined, allValues: any) => {
      if (!value && (!allValues.contactMethod || allValues.contactMethod === 'EMAIL')) {
        return REQUIRED_FIELD_WARNING;
      } else if (value && !isEmail(value)) {
        return INVALID_EMAIL_WARNING;
      }
    },
  },
  {
    name: 'patientPhone',
    conditionalValueConfig: {
      fieldNameToCheck: 'isLegallyAuthorizedRepresentative',
      valueToCheck: '',
      conditionalLogic: (value: any) => {
        return !!value;
      },
    },
    props: {
      placeholder: '(XXX)XXX-XXXX',
    },
    validate: (value: any | undefined, allValues: any) => {
      if (!value && (!allValues.contactMethod || allValues.contactMethod === 'PHONE')) {
        return REQUIRED_FIELD_WARNING;
      } else if (value && !isValidPhoneNumber(value)) {
        return INVALID_PHONE_WARNING;
      }
    },
  },
  {
    name: 'signature',
    conditionalValueConfig: {
      fieldNameToCheck: 'isLegallyAuthorizedRepresentative',
      valueToCheck: '',
      conditionalLogic: (value: any) => {
        return !!value;
      },
    },
    validate: (value: any | undefined) => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      }
    },
  },
];

const hipaaAuthorizationV3Fields = combineFormConfigs(
  hipaaAuthorizationV3FieldsFrontend,
  hipaaAuthorizationV3FieldsBase,
);

const HipaaAuthorizationV3: React.FunctionComponent<FormProps> = (props) => {
  const formState = useFormState();
  const clonedFields = useMemo(() => _.cloneDeep(hipaaAuthorizationV3Fields), []);

  if (props.validatedValues) {
    setInitialFieldsWithValidatedValues(clonedFields, props.validatedValues);
  }

  return (
    <div>
      {props.handleNavigationBack && (
        <Prompt when={formState.dirty && !formState.submitSucceeded} message={LEAVE_FORM_WARNING} />
      )}
      <div className={classNames(typography.body, styles.screen)}>
        <div className={styles.header}>
          <div className={styles.headerLogo}>
            <Logo />
          </div>
        </div>
        <div className={styles.content}>
          <p className={styles.title}>{HIPAA_AUTHORIZATION_V3_TITLE}</p>
          {formState.submitFailed ? <FormBannerError /> : undefined}
          {!props.handleNavigationSubmit ? (
            props.showAlert ? (
              <FormAlert
                setShowAlert={props.setShowAlert}
                message={SUBMIT_SUCCESS_MESSAGE}
                title={SUBMIT_SUCCESS_TITLE}
                status={AlertStatus.SUCCESS}
              />
            ) : undefined
          ) : undefined}
          <HipaaAuthorizationV3Content />
          <FormTable fields={clonedFields} />
          <div className={styles.footer}>
            {props.handleNavigationBack && (
              <div className={styles.backButton}>
                <Button
                  ariaLabel="onco-hipaa-auth-back"
                  type="button"
                  buttonType="secondary"
                  onClick={props.handleNavigationBack}
                  disabled={formState.submitting}
                >
                  Back
                </Button>
              </div>
            )}
            <Button loading={formState.submitting} ariaLabel="onco-hipaa-auth-submit" type="submit">
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const HipaaAuthorizationV3Wrapper: React.FunctionComponent<NavigationFormProps> = (props) => {
  const selectedId = props.handleNavigationSubmit ? useQuery('orderId') : null;
  const [showSuccessAlert, setShowAlert] = useState<boolean>(false);

  const onSubmit = React.useCallback(async (values: any, form: FormApi) => {
    try {
      setShowAlert(false);
      if (props.validatedValues) {
        setReadOnlyValidatedValuesOnSubmit(values, hipaaAuthorizationV3Fields, props.validatedValues);
      }
      await submitFormGeneric(HIPAA_AUTHORIZATION_V3_KEY, selectedId, values);
      if (props.handleNavigationSubmit) {
        props.handleNavigationSubmit(HIPAA_AUTHORIZATION_V3_KEY);
      } else {
        setShowAlert(true);
        form.restart();
      }
    } catch (e) {
      setShowAlert(false);
      return { [FORM_ERROR]: 'Submission Failed' };
    }
  }, []);

  return (
    <Form onSubmit={onSubmit}>
      <HipaaAuthorizationV3 showAlert={showSuccessAlert} setShowAlert={setShowAlert} {...props} />
    </Form>
  );
};

export default HipaaAuthorizationV3Wrapper;
