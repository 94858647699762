import React, { useCallback } from 'react';
import classNames from 'classnames';
import moment from 'moment';
import { FieldRenderProps } from 'react-final-form';
import { TextInput } from '@tcl-argon-prefabs/text-input';
import { GrayscalePalette } from '@tcl-boron-colors/colors';

import { FORM_DATE_FORMAT } from './FormDatePicker';
import FormError from './FormError';
import FormSignatureCanvas from './FormSignatureCanvas';
import { FormTableField } from './FormTable';

import styles from './styles.module.css';

type FormSignatureWithDateProps = FormTableField & FieldRenderProps<any>;

/* istanbul ignore next */
function noop() {
  // ignore because not used
}

const FormSignatureWithDate: React.FunctionComponent<FormSignatureWithDateProps> = ({ input, meta, isError }) => {
  return (
    <div className={styles.fieldRoot}>
      <div
        className={classNames(styles.signatureBox, styles.signatureBoxWithDate)}
        style={{
          borderColor: GrayscalePalette[300],
        }}
      >
        <div
          className={styles.signatureInnerBox}
          style={{
            borderColor: GrayscalePalette[300],
          }}
        >
          <FormSignatureCanvas
            meta={meta}
            onChange={useCallback((val) => {
              input.onChange({
                date: moment().format(FORM_DATE_FORMAT),
                signature: val,
              });
            }, [])}
          />
        </div>
      </div>

      <TextInput
        value={input.value?.date ?? ''}
        type="text"
        disabled={true}
        classes={{
          label: styles.signatureBoxWithDateDateLabel,
          root: styles.signatureBoxWithDateInputRoot,
          inputWrapper: styles.signatureBoxWithDateInputWrapper,
          input: classNames(
            isError ? styles.textInputInputErrorHidden : styles.inputInput,
            styles.signatureBoxWithDateInput,
          ),
        }}
        label="Date Signed:"
        onChange={noop}
      />
      {isError ? <FormError error={meta.error} /> : undefined}
    </div>
  );
};

export default FormSignatureWithDate;
