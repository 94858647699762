import classNames from 'classnames';
import { FORM_ERROR } from 'final-form';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { useFormState } from 'react-final-form';
import { Prompt } from 'react-router-dom';

import { Button } from '@tcl-boron-prefabs/button';
import typography from '@tcl-boron-styles/typography/dist/index.module.css';
import {
  CONSENT_TO_TEST_V2_KEY,
  CONSENT_TO_TEST_V2_TITLE,
  ConsentToTestContentV2Part1,
  ConsentToTestContentV2Part2,
  FULL_NAME_CHARACTER_LIMIT,
  consentToTestingV2Fields as consentToTestingV2FieldsBase,
} from '@tempus/patient-forms-service-shared';

import { NavigationFormProps } from '../../../../custom-to-do-list/components/NavigatorChecklist';
import {
  VALIDATED_PATIENT_DATE_OF_BIRTH_PROPERTY,
  VALIDATED_PATIENT_FIRST_NAME_PROPERTY,
  VALIDATED_PATIENT_LAST_NAME_PROPERTY,
} from '../../../../custom-to-do-list/components/Verification';
import { FormProps, submitFormGeneric } from '../../../forms';
import Form from '../../../shared/Form';
import FormAlert, { AlertStatus, SUBMIT_SUCCESS_MESSAGE, SUBMIT_SUCCESS_TITLE } from '../../../shared/FormAlert';
import FormBannerError from '../../../shared/FormBannerError';
import FormTable, { FormTableFieldFrontend, ValidatedValueConfig } from '../../../shared/FormTable';
import Logo from '../../../shared/Logo';
import styles from '../../../shared/styles.module.css';
import {
  DATE_FILTER_MIN,
  LEAVE_FORM_WARNING,
  combineFormConfigs,
  isValidDateOfBirth,
  setInitialFieldsWithValidatedValues,
  setReadOnlyValidatedValuesOnSubmit,
} from '../../../shared/utils';
import {
  CHARACTER_LIMIT_WARNING,
  INVALID_DATE_OF_BIRTH_WARNING,
  REQUIRED_FIELD_WARNING,
} from '../../../shared/validation-utils';
import _ from 'lodash';
import { useQuery } from '../../../../../api/hooks';

export { CONSENT_TO_TEST_V2_KEY };

const now = moment().toDate();

const consentToTestFieldsFrontend: FormTableFieldFrontend[] = [
  {
    name: 'patientName',
    props: {
      placeholder: 'Enter',
    },
    validate: (value: any | undefined) => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      } else if (String(value).length > FULL_NAME_CHARACTER_LIMIT) {
        return CHARACTER_LIMIT_WARNING;
      }
    },
    validatedValueConfig: {
      validatedValueNames: [VALIDATED_PATIENT_FIRST_NAME_PROPERTY, VALIDATED_PATIENT_LAST_NAME_PROPERTY],
      readOnly: true,
      concatenator: ' ',
    } as ValidatedValueConfig,
  },
  {
    name: 'dob',
    props: {
      placeholder: 'MM/DD/YY',
      minDate: DATE_FILTER_MIN,
      maxDate: now,
    },
    validate: (value: any | undefined) => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      } else if (!isValidDateOfBirth(value)) {
        return INVALID_DATE_OF_BIRTH_WARNING;
      }
    },
    validatedValueConfig: {
      validatedValueNames: [VALIDATED_PATIENT_DATE_OF_BIRTH_PROPERTY],
      readOnly: true,
    } as ValidatedValueConfig,
  },
  {
    name: 'signature',
    validate: (value: any | undefined) => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      }
    },
  },
];

const consentToTestFields = combineFormConfigs(consentToTestFieldsFrontend, consentToTestingV2FieldsBase);

const ConsentToTesting: React.FunctionComponent<FormProps> = (props) => {
  const formState = useFormState();
  const clonedFields = useMemo(() => _.cloneDeep(consentToTestFields), []);

  if (props.validatedValues) {
    setInitialFieldsWithValidatedValues(clonedFields, props.validatedValues);
  }

  return (
    <div>
      {props.handleNavigationBack && (
        <Prompt when={formState.dirty && !formState.submitSucceeded} message={LEAVE_FORM_WARNING} />
      )}
      <div className={classNames(typography.body, styles.screen)}>
        <div className={styles.header}>
          <div className={styles.headerLogo}>
            <Logo />
          </div>
        </div>
        <div className={styles.content}>
          <p className={styles.title}>{CONSENT_TO_TEST_V2_TITLE}</p>
          {formState.submitFailed ? <FormBannerError /> : undefined}
          {formState.submitSucceeded && !props.handleNavigationSubmit ? (
            props.showAlert ? (
              <FormAlert
                setShowAlert={props.setShowAlert}
                message={SUBMIT_SUCCESS_MESSAGE}
                title={SUBMIT_SUCCESS_TITLE}
                status={AlertStatus.SUCCESS}
              />
            ) : undefined
          ) : undefined}
          <ConsentToTestContentV2Part1 />
          <ConsentToTestContentV2Part2 />
          <FormTable fields={clonedFields} />
          <div className={styles.footer}>
            {props.handleNavigationBack && (
              <div className={styles.backButton}>
                <Button
                  ariaLabel="onco-consent-to-test-back"
                  type="button"
                  buttonType="secondary"
                  onClick={props.handleNavigationBack}
                  disabled={formState.submitting}
                >
                  Back
                </Button>
              </div>
            )}
            <Button loading={formState.submitting} ariaLabel="onco-consent-to-test-generic-submit" type="submit">
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const ConsentToTestingV2Wrapper: React.FunctionComponent<NavigationFormProps> = (props) => {
  const selectedId = props.handleNavigationSubmit ? useQuery('orderId') : null;
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);

  const onSubmit = React.useCallback(async (values: any) => {
    try {
      if (props.validatedValues) {
        setReadOnlyValidatedValuesOnSubmit(values, consentToTestFields, props.validatedValues);
      }

      await submitFormGeneric(CONSENT_TO_TEST_V2_KEY, selectedId, values);
      if (props.handleNavigationSubmit) {
        props.handleNavigationSubmit(CONSENT_TO_TEST_V2_KEY);
      } else {
        setShowSuccessAlert(true);
      }
    } catch (e) {
      setShowSuccessAlert(false);
      return { [FORM_ERROR]: 'Submission Failed' };
    }
  }, []);

  return (
    <Form onSubmit={onSubmit}>
      <ConsentToTesting showAlert={showSuccessAlert} setShowAlert={setShowSuccessAlert} {...props} />
    </Form>
  );
};

export default ConsentToTestingV2Wrapper;
