import React from 'react';
import isEmail from 'validator/lib/isEmail';
import typography from '@tcl-boron-styles/typography/dist/index.module.css';
import {
  FULL_NAME_CHARACTER_LIMIT,
  RELATIONSHIP_TO_PATIENT_NAME_V2,
  RelationshipToPatientV2,
  consentToApplicationV2Fields as consentToApplicationV2FieldsBase,
  getRelationshipToPatientDropdownOptionsV2,
} from '@tempus/patient-forms-service-shared';

import FormTable, { FormTableFieldFrontend } from '../../shared/FormTable';
import { combineFormConfigs, isValidPhoneNumber } from '../../shared/utils';
import {
  CHARACTER_LIMIT_WARNING,
  INVALID_EMAIL_WARNING,
  INVALID_PHONE_WARNING,
  REQUIRED_FIELD_WARNING,
} from '../../shared/validation-utils';

export const consentToApplicationV2FieldsFrontend: FormTableFieldFrontend[] = [
  {
    name: RELATIONSHIP_TO_PATIENT_NAME_V2,
    props: {
      placeholder: 'Select',
      dropdownOptions: getRelationshipToPatientDropdownOptionsV2(),
    },
    validate: (value: any | undefined) => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      }
    },
  },
  {
    name: 'fullName',
    validate: (value: any | undefined) => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      } else if (String(value).length > FULL_NAME_CHARACTER_LIMIT) {
        return CHARACTER_LIMIT_WARNING;
      }
    },
    conditionalValueConfig: {
      fieldNameToCheck: RELATIONSHIP_TO_PATIENT_NAME_V2,
      valueToCheck: RelationshipToPatientV2.REPRESENTATIVE,
      conditionalLogic: (value: any, conditionalValue: string) => {
        return value === conditionalValue;
      },
    },
  },
  {
    name: 'email',
    validate: (value: any | undefined) => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      } else if (!isEmail(value)) {
        return INVALID_EMAIL_WARNING;
      }
    },
    conditionalValueConfig: {
      fieldNameToCheck: RELATIONSHIP_TO_PATIENT_NAME_V2,
      valueToCheck: RelationshipToPatientV2.REPRESENTATIVE,
      conditionalLogic: (value: any, conditionalValue: string) => {
        return value === conditionalValue;
      },
    },
  },
  {
    name: 'phone',
    props: {
      placeholder: '(XXX)XXX-XXXX',
    },
    validate: (value: any | undefined) => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      } else if (!isValidPhoneNumber(value)) {
        return INVALID_PHONE_WARNING;
      }
    },
    conditionalValueConfig: {
      fieldNameToCheck: RELATIONSHIP_TO_PATIENT_NAME_V2,
      valueToCheck: RelationshipToPatientV2.REPRESENTATIVE,
      conditionalLogic: (value: any, conditionalValue: string) => {
        return value === conditionalValue;
      },
    },
  },
  {
    name: 'patientConsent',
    validate: (value: any | undefined) => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      }
    },
    conditionalValueConfig: {
      fieldNameToCheck: RELATIONSHIP_TO_PATIENT_NAME_V2,
      valueToCheck: RelationshipToPatientV2.SELF,
      conditionalLogic: (value: any, conditionalValue: string) => {
        return value === conditionalValue;
      },
    },
  },
  {
    name: 'patientRepresentativeConsent',
    validate: (value: any | undefined) => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      }
    },
    conditionalValueConfig: {
      fieldNameToCheck: RELATIONSHIP_TO_PATIENT_NAME_V2,
      valueToCheck: RelationshipToPatientV2.REPRESENTATIVE,
      conditionalLogic: (value: any, conditionalValue: string) => {
        return value === conditionalValue;
      },
    },
  },
  {
    name: 'signature',
    validate: (value: any | undefined) => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      }
    },
  },
];

export const consentToApplicationV2Fields = combineFormConfigs(
  consentToApplicationV2FieldsFrontend,
  consentToApplicationV2FieldsBase,
);

const ConsentToApplicationTableV2: React.FunctionComponent = () => {
  return (
    <>
      <div className={typography.subSectionHeader}>Consent to Application</div>
      <FormTable fields={consentToApplicationV2Fields} />
    </>
  );
};

export default ConsentToApplicationTableV2;
