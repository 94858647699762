import * as _ from 'lodash';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { useFormState } from 'react-final-form';
import { DropdownOption } from '@tcl-argon-models/models';
import {
  CONSENT_TO_TEST_V3_KEY,
  CONSENT_TO_TEST_V3_TITLE,
  ConsentToTestContentV3Part1,
  ConsentToTestContentV3Part2,
  TEXT_FIELD_CHARACTER_LIMIT,
  consentToTestingV3Fields,
} from '@tempus/patient-forms-service-shared';
import FormTable, { FormTableFieldFrontend, ValidatedValueConfig } from '../../../shared/FormTable';
import {
  CHARACTER_LIMIT_WARNING,
  INVALID_DATE_OF_BIRTH_WARNING,
  REQUIRED_FIELD_WARNING,
} from '../../../shared/validation-utils';
import { NavigationFormProps } from '../../../../custom-to-do-list/components/NavigatorChecklist';
import { FormProps, submitFormGeneric } from '../../../forms';
import {
  DATE_FILTER_MIN,
  LEAVE_FORM_WARNING,
  combineFormConfigs,
  isValidDateOfBirth,
  setInitialFieldsWithValidatedValues,
  setReadOnlyValidatedValuesOnSubmit,
} from '../../../shared/utils';
import { FORM_ERROR, FormApi } from 'final-form';
import { Prompt } from 'react-router-dom';
import classNames from 'classnames';
import typography from '@tcl-boron-styles/typography/dist/index.module.css';
import styles from '../../../shared/styles.module.css';
import Logo from '../../../shared/Logo';
import FormBannerError from '../../../shared/FormBannerError';
import FormAlert, { AlertStatus, SUBMIT_SUCCESS_MESSAGE, SUBMIT_SUCCESS_TITLE } from '../../../shared/FormAlert';
import { Button } from '@tcl-boron-prefabs/button';
import Form from '../../../shared/Form';
import {
  VALIDATED_PATIENT_DATE_OF_BIRTH_PROPERTY,
  VALIDATED_PATIENT_FIRST_NAME_PROPERTY,
  VALIDATED_PATIENT_LAST_NAME_PROPERTY,
} from '../../../../custom-to-do-list/components/Verification';
import { useQuery } from '../../../../../api/hooks';

enum OPTION {
  YES = 'YES',
  NO = 'NO',
}

const IS_LEGALLY_AUTHORIZED_REPRESENTATIVE_OPTIONS: DropdownOption[] = [
  {
    label: 'I am the patient',
    value: OPTION.NO,
  },
  {
    label: 'I am a Legally Authorized Representative',
    value: OPTION.YES,
  },
];

export const CONSENT_TO_TEST_V3_GENERIC_KEY = CONSENT_TO_TEST_V3_KEY;

const FORM_FRONTEND_FIELDS: FormTableFieldFrontend[] = [
  {
    name: 'isLegallyAuthorizedRepresentative',
    props: {
      placeholder: 'Select',
      dropdownOptions: IS_LEGALLY_AUTHORIZED_REPRESENTATIVE_OPTIONS,
    },
    validate: (value: any | undefined): string | undefined => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      }
    },
  },
  {
    conditionalValueConfig: {
      fieldNameToCheck: 'isLegallyAuthorizedRepresentative',
      valueToCheck: OPTION.YES,
      conditionalLogic: (value: any, conditionalValue: string) => {
        return value === conditionalValue;
      },
    },
    name: 'legallyAuthorizedRepresentativeFirstName',
    validate: (value: any | undefined): string | undefined => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      } else if (String(value).length > TEXT_FIELD_CHARACTER_LIMIT) {
        return CHARACTER_LIMIT_WARNING;
      }
    },
  },
  {
    conditionalValueConfig: {
      fieldNameToCheck: 'isLegallyAuthorizedRepresentative',
      valueToCheck: OPTION.YES,
      conditionalLogic: (value: any, conditionalValue: string) => {
        return value === conditionalValue;
      },
    },
    name: 'legallyAuthorizedRepresentativeLastName',
    validate: (value: any | undefined): string | undefined => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      } else if (String(value).length > TEXT_FIELD_CHARACTER_LIMIT) {
        return CHARACTER_LIMIT_WARNING;
      }
    },
  },
  {
    conditionalValueConfig: {
      fieldNameToCheck: 'isLegallyAuthorizedRepresentative',
      valueToCheck: OPTION.YES,
      conditionalLogic: (value: any, conditionalValue: string) => {
        return value === conditionalValue;
      },
    },
    name: 'legallyAuthorizedRepresentativeRelationshipToPatient',
    validate: (value: any | undefined): string | undefined => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      } else if (String(value).length > TEXT_FIELD_CHARACTER_LIMIT) {
        return CHARACTER_LIMIT_WARNING;
      }
    },
  },
  {
    conditionalValueConfig: {
      fieldNameToCheck: 'isLegallyAuthorizedRepresentative',
      valueToCheck: '',
      conditionalLogic: (value: any) => {
        return !!value;
      },
    },
    name: 'patientFirstName',
    validate: (value: any | undefined): string | undefined => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      } else if (String(value).length > TEXT_FIELD_CHARACTER_LIMIT) {
        return CHARACTER_LIMIT_WARNING;
      }
    },
    validatedValueConfig: {
      validatedValueNames: [VALIDATED_PATIENT_FIRST_NAME_PROPERTY],
      readOnly: true,
      concatenator: ' ',
    } as ValidatedValueConfig,
  },
  {
    conditionalValueConfig: {
      fieldNameToCheck: 'isLegallyAuthorizedRepresentative',
      valueToCheck: '',
      conditionalLogic: (value: any) => {
        return !!value;
      },
    },
    name: 'patientLastName',
    validate: (value: any | undefined): string | undefined => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      } else if (String(value).length > TEXT_FIELD_CHARACTER_LIMIT) {
        return CHARACTER_LIMIT_WARNING;
      }
    },
    validatedValueConfig: {
      validatedValueNames: [VALIDATED_PATIENT_LAST_NAME_PROPERTY],
      readOnly: true,
      concatenator: ' ',
    } as ValidatedValueConfig,
  },
  {
    conditionalValueConfig: {
      fieldNameToCheck: 'isLegallyAuthorizedRepresentative',
      valueToCheck: '',
      conditionalLogic: (value: any) => {
        return !!value;
      },
    },
    name: 'patientDateOfBirth',
    props: {
      placeholder: 'MM/DD/YY',
      minDate: DATE_FILTER_MIN,
      maxDate: moment().toDate(),
    },
    validate: (value: any | undefined): string | undefined => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      } else if (!isValidDateOfBirth(value)) {
        return INVALID_DATE_OF_BIRTH_WARNING;
      }
    },
    validatedValueConfig: {
      validatedValueNames: [VALIDATED_PATIENT_DATE_OF_BIRTH_PROPERTY],
      readOnly: true,
    } as ValidatedValueConfig,
  },
  {
    conditionalValueConfig: {
      fieldNameToCheck: 'isLegallyAuthorizedRepresentative',
      valueToCheck: '',
      conditionalLogic: (value: any) => {
        return !!value;
      },
    },
    name: 'signature',
    validate: (value: any | undefined): string | undefined => {
      if (!value) {
        return REQUIRED_FIELD_WARNING;
      }
    },
  },
  {
    conditionalValueConfig: {
      fieldNameToCheck: 'isLegallyAuthorizedRepresentative',
      valueToCheck: OPTION.YES,
      conditionalLogic: (value: any, conditionalValue: string) => {
        return value === conditionalValue;
      },
    },
    name: 'patientSignature',
  },
];

const FORM_FIELDS = combineFormConfigs(FORM_FRONTEND_FIELDS, consentToTestingV3Fields);

const ConsentToTest: React.FunctionComponent<FormProps> = (props) => {
  const formState = useFormState();
  const clonedFields = useMemo(() => _.cloneDeep(FORM_FIELDS), []);

  if (props.validatedValues) {
    setInitialFieldsWithValidatedValues(clonedFields, props.validatedValues);
  }

  return (
    <div>
      {props.handleNavigationBack && (
        <Prompt when={formState.dirty && !formState.submitSucceeded} message={LEAVE_FORM_WARNING} />
      )}
      <div className={classNames(typography.body, styles.screen)}>
        <div className={styles.header}>
          <div className={styles.headerLogo}>
            <Logo />
          </div>
        </div>
        <div className={styles.content}>
          <p className={styles.title}>{CONSENT_TO_TEST_V3_TITLE}</p>
          {formState.submitFailed ? <FormBannerError /> : undefined}
          {!props.handleNavigationSubmit ? (
            props.showAlert ? (
              <FormAlert
                setShowAlert={props.setShowAlert}
                message={SUBMIT_SUCCESS_MESSAGE}
                title={SUBMIT_SUCCESS_TITLE}
                status={AlertStatus.SUCCESS}
              />
            ) : undefined
          ) : undefined}
          <ConsentToTestContentV3Part1 />
          <ConsentToTestContentV3Part2 />
          <FormTable fields={clonedFields} />
          <div className={styles.footer}>
            {props.handleNavigationBack && (
              <div className={styles.backButton}>
                <Button
                  ariaLabel="onco-consent-to-test-back"
                  type="button"
                  buttonType="secondary"
                  onClick={props.handleNavigationBack}
                  disabled={formState.submitting}
                >
                  Back
                </Button>
              </div>
            )}
            <Button loading={formState.submitting} ariaLabel="onco-consent-to-test-generic-submit" type="submit">
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const ConsentToTestV3Wrapper: React.FunctionComponent<NavigationFormProps> = (props) => {
  const selectedId = props.handleNavigationSubmit ? useQuery('orderId') : null;
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);

  const onSubmit = React.useCallback(async (values: any, form: FormApi) => {
    try {
      setShowSuccessAlert(false);
      if (props.validatedValues) {
        setReadOnlyValidatedValuesOnSubmit(values, FORM_FIELDS, props.validatedValues);
      }

      await submitFormGeneric(CONSENT_TO_TEST_V3_KEY, selectedId, values);
      if (props.handleNavigationSubmit) {
        props.handleNavigationSubmit(CONSENT_TO_TEST_V3_KEY);
      } else {
        setShowSuccessAlert(true);
        form.restart();
      }
    } catch (e) {
      setShowSuccessAlert(false);
      return { [FORM_ERROR]: 'Submission Failed' };
    }
  }, []);

  return (
    <Form onSubmit={onSubmit}>
      <ConsentToTest showAlert={showSuccessAlert} setShowAlert={setShowSuccessAlert} {...props} />
    </Form>
  );
};

export default ConsentToTestV3Wrapper;
