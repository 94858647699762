import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import Checklist from './Checklist';
import { Route, RouteProps, Switch } from 'react-router-dom';
import NavigatorFormElement from './NavigatorFormElement';
import { StatusBadgeProps } from '../../forms/shared/StatusBadge';
import { ConsentAppForm } from '@tempus/patient-forms-service-shared';
import { ValidatedValues } from './Verification';

export type NavigationFormProps = {
  handleNavigationSubmit?: (key: string, values?: any) => void;
  handleNavigationBack?: () => void;
  validatedValues?: ValidatedValues;
  isInStandaloneChecklist?: boolean;
};

export type NavigatorFormState = {
  complete: boolean;
  navigatorForm: NavigatorForm;
  formModel: ConsentAppForm;
};

export type NavigatorForm = {
  key: string;
  Element: React.ReactElement;
  routeProps: RouteProps;
};

type NavigatorChecklistProps = {
  formList: ConsentAppForm[];
  validatedValues?: ValidatedValues;
  history: any;
  isStandalone?: boolean;
};

const setNavigatorFormState = (
  formList: ConsentAppForm[],
  handleSuccessfulSubmit: (formKey: string) => void,
  handleBackButton: () => void,
  formUrlPrefix: string,
  isStandalone: boolean,
  validatedValues?: ValidatedValues,
) => {
  const formState: NavigatorFormState[] = [];
  for (const form of formList) {
    const navigatorForm: NavigatorForm = {
      key: form.key,
      Element: NavigatorFormElement(form.key, handleSuccessfulSubmit, handleBackButton, isStandalone, validatedValues),
      routeProps: {
        exact: true,
        path: `${formUrlPrefix}/${form.link}`,
      },
    };
    formState.push({ complete: form.complete ?? false, navigatorForm: navigatorForm, formModel: form });
  }

  return formState;
};

const NavigatorChecklist: React.FunctionComponent<NavigatorChecklistProps> = (props) => {
  const checklistUrl = props.isStandalone ? '/standalone-checklist' : '/checklist';
  const handleSuccessfulSubmit = (formKey: string, badgeValues?: StatusBadgeProps) => {
    const tempState = formList.slice();
    const keyIndex = formList.findIndex((formState) => formState.formModel.key === formKey);
    tempState[keyIndex].complete = true;

    if (badgeValues) {
      tempState[keyIndex].formModel.financialAidInfo = {
        title: badgeValues.title,
        statement: badgeValues.text,
        status: badgeValues.type,
      };
    }
    setFormList(tempState);
    setShowSucessBanner(true);
    props.history.goBack();
  };

  const handleBackButton = () => {
    setShowSucessBanner(false);
    props.history.goBack();
  };

  const formUrlPrefix = `${checklistUrl}/form`;

  const [formList, setFormList] = useState<NavigatorFormState[]>(
    setNavigatorFormState(
      props.formList,
      handleSuccessfulSubmit,
      handleBackButton,
      formUrlPrefix,
      props.isStandalone ?? false,
      props.validatedValues,
    ),
  );
  const [showSuccessBanner, setShowSucessBanner] = useState<boolean>(false);

  const onBeforeUnload = useCallback((event: any) => {
    // Ask for confirmation before navigating away - other than clicking links.
    if (event.target.activeElement.nodeName !== 'A') {
      // When any string is returned, the browser will render a confirmation alert (not customizable) before unload
      return '';
    }
  }, []);

  useEffect(() => {
    window.addEventListener('beforeunload', onBeforeUnload);
  }, []);

  useLayoutEffect(() => {
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, []);

  return (
    <nav>
      <Switch>
        <Route exact path={checklistUrl}>
          <Checklist
            navigatorState={formList}
            showSuccessBanner={showSuccessBanner}
            validatedValues={props.validatedValues}
            formUrlPrefix={formUrlPrefix}
          />
        </Route>
        {Object.values(formList).map((formRoute, index) => (
          <Route key={index} {...formRoute.navigatorForm.routeProps}>
            {formRoute.navigatorForm.Element}
          </Route>
        ))}
      </Switch>
    </nav>
  );
};

export default NavigatorChecklist;
