import React from 'react';
import { RouteProps } from 'react-router-dom';

import { AuthenticationType } from '../../api/AuthenticationType';
import fetchHelper from '../../utils';
import { NavigationFormProps } from '../custom-to-do-list/components/NavigatorChecklist';
import { ValidatedValues } from '../custom-to-do-list/components/Verification';
import { TEMPUS_PRO_PATH, TempusPro } from './psychiatry';
import { ConsentToTestGenericV3 } from './consent-to-test/generic/v3';
import { HipaaAuthorizationV3 } from './hipaa-authorization/v3';
import { FinancialAssistanceV3 } from './financial-assistance/v3';

type ConfiguredRouteDetails = {
  title?: string;
  routeProps: RouteProps;
  Component: React.ComponentType;
};

type FormRouteConfig = ConfiguredRouteDetails & {
  toggles: ViewConfigurationToggles;
};

type ViewConfigurationToggles = (() => boolean)[];

enum FormRoute {
  TEMPUS_PRO = 'tempusPro',
  CONSENT_TO_TEST = 'consent-to-test',
  HIPAA_AUTH = 'hipaa-auth',
  FINANCIAL_ASSISTANCE = 'financial-assistance',
}

export type FormProps = NavigationFormProps & {
  showAlert: boolean;
  setShowAlert: (value: boolean) => void;
  validatedValues?: ValidatedValues;
};

export type FormTableProps = {
  validatedValues?: ValidatedValues;
};

const forms: Record<FormRoute, FormRouteConfig> = {
  [FormRoute.TEMPUS_PRO]: {
    title: 'Tempus PRO',
    toggles: [],
    Component: TempusPro,
    routeProps: {
      exact: false,
      path: TEMPUS_PRO_PATH,
    },
  },
  [FormRoute.CONSENT_TO_TEST]: {
    title: 'Consent to Test',
    toggles: [],
    Component: ConsentToTestGenericV3,
    routeProps: {
      exact: false,
      path: '/form/consent-to-test',
    },
  },
  [FormRoute.HIPAA_AUTH]: {
    title: 'HIPAA Notice and Authorization',
    toggles: [],
    Component: HipaaAuthorizationV3,
    routeProps: {
      exact: false,
      path: '/form/hipaa-auth',
    },
  },
  [FormRoute.FINANCIAL_ASSISTANCE]: {
    title: 'Financial Assistance Application',
    toggles: [],
    Component: FinancialAssistanceV3,
    routeProps: {
      exact: false,
      path: '/form/financial-assistance',
    },
  },
};

const filterToggles = (route: FormRouteConfig): boolean => route.toggles.map((toggle) => toggle()).every(Boolean);
const getFormRoutes = () => Object.values(forms).filter(filterToggles);

export type FinancialAidDto<T> = {
  statement: string;
  status: T;
  title: string;
};

const submitFormGeneric = <T,>(formType: string, orderId: string | null, data: any): Promise<T> => {
  // filter out conditional values which could could be empty strings
  Object.keys(data).forEach((key) => {
    if (!data[key]) {
      data[key] = undefined;
    }
  });

  if (orderId) {
    return fetchHelper(`/v4/form`, {
      authenticationType: AuthenticationType.IDENTITY,
      method: 'POST',
      body: {
        formType,
        orderId,
        data,
      },
    });
  } else {
    return fetchHelper(`/v4/form/stand-alone`, {
      authenticationType: AuthenticationType.NONE,
      method: 'POST',
      body: {
        formType,
        data,
      },
    });
  }
};

export { getFormRoutes, submitFormGeneric };
