import React, { ChangeEvent, useEffect } from 'react';
import moment from 'moment';
import { FieldRenderProps } from 'react-final-form';
import { createUseStyles } from 'react-jss';
import { Calendar } from '@tcl-boron-icons/icons';
import { TextInput } from '@tcl-boron-prefabs/text-input';
import { Popover } from '@tempus/component-library';
import DatePicker from '@tempus/component-library/components/DatePicker';

import FormError from './FormError';
import { FormTableField } from './FormTable';
import datePickerStyles from './styles';

import styles from './styles.module.css';
import classNames from 'classnames';

type FormDatePickerProps = FormTableField &
  FieldRenderProps<any> & {
    placeholder?: string;
    minDate?: Date;
    maxDate?: Date;
  };

export const FORM_DATE_FORMAT = 'MM/DD/YYYY';

const TextInputDatePicker = (props: any) => {
  return (
    <TextInput
      hideLabel
      classes={{
        root: props.isError ? '' : styles.inputRoot,
        inputWrapper: styles.inputWrapper,
        input: props.isError ? styles.textInputInputError : styles.inputInput,
      }}
      {...props}
      rightIcon={
        <div className={styles.calendarIcon}>
          <Calendar />
        </div>
      }
      value={props.value}
      onChange={(value: any) => {
        props.onChange(null, value);
      }}
      onBlur={(e: any) => {
        props.onBlur(e);
      }}
    />
  );
};

const PopoverComponent = (props: any) => {
  return (
    <Popover
      {...props}
      popperProps={{
        ...props.popperProps,
        popperOptions: {
          ...props.popperProps.popperOptions,
          placement: 'bottom-start',
        },
      }}
    />
  );
};

const useDatePickerStyles = createUseStyles(datePickerStyles.datePicker);

// Override the tclv2 DatePicker styles to resemble Boron, copied from x-portal
const StyledHookDatePicker = (props: any) => {
  const classes = useDatePickerStyles();
  return <DatePicker classes={classes} {...props} />;
};

const FormDatePicker: React.FunctionComponent<FormDatePickerProps> = ({
  input,
  meta,
  placeholder,
  minDate,
  maxDate,
  readOnly,
  isError,
}) => {
  const [value, setValue] = React.useState(input.value);

  useEffect(() => {
    if (!input.value) {
      setValue('');
    }
  }, [input.value]);

  return (
    <div className={classNames(styles.fieldRoot, styles.tableRowContentFocusArea, styles.datePickerRoot)}>
      <StyledHookDatePicker
        disabled={meta.submitting || readOnly}
        label={input.name}
        targetProps={{
          isError,
        }}
        hideLabel
        placeholder={placeholder}
        TargetComponent={TextInputDatePicker}
        PopoverComponent={PopoverComponent}
        value={value}
        onChange={(e: ChangeEvent, value: any) => {
          setValue(value);
          input.onChange(moment(value).format(FORM_DATE_FORMAT));
        }}
        onBlur={(e: any) => {
          setValue(moment(value).format(FORM_DATE_FORMAT));
          input.onBlur(e);
        }}
        popp
        min={minDate}
        max={maxDate}
      />
      {isError ? <FormError error={meta.error} /> : undefined}
    </div>
  );
};

export default FormDatePicker;
