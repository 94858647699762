import { SingleSelectDropdown } from '@tcl-boron-prefabs/single-select-dropdown';
import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import styles from './styles.module.css';
import { FormTableField } from './FormTable';
import { DropdownOption } from '@tcl-argon-models/models';
import FormError from './FormError';
import classNames from 'classnames';

type FormSingleSelectDropdownProps = FormTableField &
  FieldRenderProps<any> & {
    placeholder?: string;
    dropdownOptions?: DropdownOption[];
  };

const FormSingleSelectDropdown: React.FunctionComponent<FormSingleSelectDropdownProps> = ({
  input,
  meta,
  placeholder,
  dropdownOptions,
  isError,
}) => {
  return (
    <div className={classNames(styles.fieldRoot, styles.tableRowContentFocusArea)}>
      <SingleSelectDropdown
        {...input}
        onChange={(value) => {
          input.onChange(value ? value.value : null);
        }}
        value={dropdownOptions?.find(({ value }) => value === input.value)}
        classes={{
          root: isError ? '' : styles.inputRoot,
          inputWrapper: styles.inputWrapper,
          valueContainer: styles.singleSelectDropdownValueContainer,
          value: isError ? styles.singleSelectDropdownValueError : styles.singleSelectDropdownValue,
          placeholder: styles.dropdownPlaceholder,
          menu: styles.singleSelectDropdownMenu,
        }}
        label={input.name}
        disabled={meta.submitting}
        placeholder={placeholder}
        hideLabel
        options={dropdownOptions ?? []}
        dropdownType="secondary"
      />
      {isError ? <FormError error={meta.error} /> : undefined}
    </div>
  );
};

export default FormSingleSelectDropdown;
