import React from 'react';
import { useHistory } from 'react-router-dom';
import { ConsentAppForm } from '@tempus/patient-forms-service-shared';

import NavigatorChecklist from './NavigatorChecklist';

// TODO: get this from patient-forms-service
const STANDALONE_FORM_LIST: ConsentAppForm[] = [
  {
    key: 'FINANCIAL_ASSISTANCE_V3',
    title: 'Financial Assistance',
    description:
      'Tempus has a Financial Assistance Program to help provide access to our tests for patients in financial need. Approval of the financial assistance application is based on your household income and takes into account relevant life circumstances.',
    link: 'financial-assistance',
    required: false,
    complete: false,
  },
  {
    key: 'ONCOLOGY_CONSENT_TO_TEST_V3',
    title: 'Consent to Testing',
    description:
      'This document provides your consent for Tempus to run the test(s) your doctor ordered, bill your insurance, and use your specimens and health information. Complete this form if you are a patient in AK, CA, FL, GA, MI, MN, NE, NJ, OR, SD, TX, or VT, or your health care provider asks you to sign.',
    link: 'consent-to-test',
    required: true,
    complete: false,
  },
  {
    key: 'HIPAA_AUTHORIZATION_V3',
    title: 'Notice and Authorization for Health Records',
    description:
      'Tempus is working to make precision medicine a reality for patients. Please complete this form if you are interested in helping us with that mission.',
    link: 'hipaa-authorization',
    required: false,
    complete: false,
  },
];

const CustomToDoListRoot: React.FunctionComponent = () => {
  const history = useHistory();
  return <NavigatorChecklist formList={STANDALONE_FORM_LIST} history={history} isStandalone />;
};

export default CustomToDoListRoot;
