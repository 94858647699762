import React, { useState } from 'react';
import classNames from 'classnames';
import { FORM_ERROR, FormApi } from 'final-form';
import { useFormState } from 'react-final-form';
import { Prompt } from 'react-router-dom';
import { Button } from '@tcl-boron-prefabs/button';
import typography from '@tcl-boron-styles/typography/dist/index.module.css';
import {
  FINANCIAL_ASSISTANCE_FORM_V3_KEY,
  FinancialAssistanceCalculationStatus,
} from '@tempus/patient-forms-service-shared';
import { NavigationFormProps } from '../../../custom-to-do-list/components/NavigatorChecklist';
import { FinancialAidDto, FormProps, submitFormGeneric } from '../../forms';
import Form from '../../shared/Form';
import FormAlert, { AlertStatus } from '../../shared/FormAlert';
import FormBannerError from '../../shared/FormBannerError';
import { FormTableField } from '../../shared/FormTable';
import Logo from '../../shared/Logo';
import { StatusBadgeProps } from '../../shared/StatusBadge';
import {
  LEAVE_FORM_WARNING,
  deleteFalsyStringFields,
  deleteUnmetConditionalFields,
  setReadOnlyValidatedValuesOnSubmit,
} from '../../shared/utils';
import ConsentToApplicationTableV3, { consentToApplicationV3Fields } from './ConsentToApplicationV3Table';
import ExtenuatingCircumstancesTable, { extenuatingCircumstancesV3Fields } from './ExtenuatingCircumstancesV3Table';
import OrderingPhysicianTableV3, { orderingPhysicianV3Fields } from './OrderingPhysicianV3Table';
import PatientInformationTable, { patientInformationV3Fields } from './PatientInformationV3Table';

import styles from '../../shared/styles.module.css';
import { useQuery } from '../../../../api/hooks';
import { FAOncoNeuroSelector } from '../shared/OncoNeuroSelector';

export const FINANCIAL_ASSISTANCE_V3_KEY = FINANCIAL_ASSISTANCE_FORM_V3_KEY;

type FinancialAidV3FormProps = FormProps & {
  statement: string;
  status: FinancialAssistanceCalculationStatus;
  title: string;
};

const FinancialAssistanceV3: React.FunctionComponent<FinancialAidV3FormProps> = (props) => {
  const formState = useFormState();

  return (
    <div>
      {props.handleNavigationBack && (
        <Prompt when={formState.dirty && !formState.submitSucceeded} message={LEAVE_FORM_WARNING} />
      )}
      <div className={classNames(typography.body, styles.screen)}>
        <div className={styles.header}>
          <div className={styles.headerLogo}>
            <Logo />
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.title}>Financial Assistance</div>
          {formState.submitFailed ? <FormBannerError /> : undefined}
          {!props.handleNavigationSubmit ? (
            props.showAlert ? (
              <FormAlert
                setShowAlert={props.setShowAlert}
                message={props.statement}
                title={props.title}
                status={
                  props.status === FinancialAssistanceCalculationStatus.APPROVED
                    ? AlertStatus.SUCCESS
                    : AlertStatus.WARNING
                }
              />
            ) : undefined
          ) : undefined}
          <div className={styles.subtitle}>Please complete all fields</div>
          <PatientInformationTable validatedValues={props.validatedValues} />
          <OrderingPhysicianTableV3 />
          <ExtenuatingCircumstancesTable />
          <ConsentToApplicationTableV3 />
          <div className={styles.footer}>
            {props.handleNavigationBack && (
              <div className={styles.backButton}>
                <Button
                  ariaLabel="financial-assistance-back"
                  type="button"
                  buttonType="secondary"
                  onClick={props.handleNavigationBack}
                  disabled={formState.submitting}
                >
                  Back
                </Button>
              </div>
            )}
            <Button loading={formState.submitting} ariaLabel="financial-assistance-submit" type="submit">
              Submit
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

const getAllFinancialAssistanceV3TableFormFields = (): FormTableField[] => {
  return patientInformationV3Fields.concat(
    orderingPhysicianV3Fields,
    consentToApplicationV3Fields,
    extenuatingCircumstancesV3Fields,
  );
};

const FinancialAssistanceV3Wrapper: React.FunctionComponent<NavigationFormProps> = (props) => {
  const selectedId = props.handleNavigationSubmit ? useQuery('orderId') : null;
  const [financialAidStatus, setFinancialAidStatus] = useState<FinancialAssistanceCalculationStatus>(
    FinancialAssistanceCalculationStatus.DENIED,
  );
  const [financialAidStatement, setFinancialAidStatement] = useState<string>('');
  const [financialAidTitle, setFinancialAidTitle] = useState<string>('');
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const onSubmit = React.useCallback(async (values: any, form: FormApi) => {
    try {
      setShowAlert(false);
      if (props.validatedValues) {
        setReadOnlyValidatedValuesOnSubmit(values, patientInformationV3Fields, props.validatedValues);
      }
      deleteUnmetConditionalFields(values, getAllFinancialAssistanceV3TableFormFields());
      deleteFalsyStringFields(values);

      const { statement, status, title } = await submitFormGeneric<
        FinancialAidDto<FinancialAssistanceCalculationStatus>
      >(FINANCIAL_ASSISTANCE_V3_KEY, selectedId, values);

      if (props.handleNavigationSubmit) {
        const badgeValues: StatusBadgeProps = {
          text: statement,
          title: title,
          type: status,
        };
        props.handleNavigationSubmit(FINANCIAL_ASSISTANCE_V3_KEY, badgeValues);
      } else {
        setFinancialAidStatus(status);
        setFinancialAidStatement(statement);
        setFinancialAidTitle(title);
        setShowAlert(true);
        form.restart();
      }
    } catch (e) {
      setShowAlert(false);
      return { [FORM_ERROR]: 'Submission Failed' };
    }
  }, []);

  return (
    <FAOncoNeuroSelector {...props} formKey={FINANCIAL_ASSISTANCE_V3_KEY}>
      <Form onSubmit={onSubmit}>
        <FinancialAssistanceV3
          showAlert={showAlert}
          setShowAlert={setShowAlert}
          title={financialAidTitle}
          statement={financialAidStatement}
          status={financialAidStatus}
          {...props}
        />
      </Form>
    </FAOncoNeuroSelector>
  );
};

export default FinancialAssistanceV3Wrapper;
