import React from 'react';
import styles from '../../forms/shared/styles.module.css';
import checklistStyles from '../checklistStyles.module.css';
import typography from '@tcl-boron-styles/typography/dist/index.module.css';
import classNames from 'classnames';
import { GrayCheckmark } from '../assets/GrayCheckmark';
import { BlueCheckmark } from '../assets/BlueCheckmark';
import { Link } from 'react-router-dom';
import StatusBadge from '../../forms/shared/StatusBadge';
import { useQuery } from '../../../api/hooks';
import { ConsentAppForm } from '@tempus/patient-forms-service-shared';

type ChecklistTableProps = {
  tableTitle: string;
  forms: ConsentAppForm[];
  complete: boolean;
  formUrlPrefix: string;
};

const getChecklistRowTitle = (form: ConsentAppForm) => {
  if (form.required) {
    return form.title + ' (Required)';
  }
  return form.title;
};

const renderChecklistFormField = (form: ConsentAppForm, complete: boolean, formUrlPrefix: string) => {
  const orderId = useQuery('orderId');
  const link = `${formUrlPrefix}/${form.link}${orderId ? `?orderId=${orderId}` : ''}`;

  return (
    <div>
      <div className={checklistStyles.checklistContentRow}>
        <div>{form.description}</div>
        <div className={checklistStyles.checklistCheckbox}>{complete ? <BlueCheckmark /> : <GrayCheckmark />}</div>
      </div>
      {complete ? (
        <div className={checklistStyles.checklistCompleteDoc}>Document complete</div>
      ) : (
        <Link to={link} className={checklistStyles.completeLink} id={`complete-document-link-${form.key}`}>
          Complete Document
        </Link>
      )}
    </div>
  );
};

const ChecklistTable: React.FunctionComponent<ChecklistTableProps> = (props) => {
  return (
    <div>
      <div className={typography.subSectionHeader}>{props.tableTitle}</div>
      <div className={styles.table}>
        {props.forms.map((formModel, index) => {
          return (
            <div key={index} className={styles.tableRow} id={`field-type-${formModel.link}`}>
              <div className={classNames(styles.tableRowTitle, styles.tableChecklistRowTitle, styles.tableCell)}>
                <div className={styles.tableRowLabelContainer}>
                  <span className={classNames(styles.tableRowTitleText, styles.bold)}>
                    {getChecklistRowTitle(formModel)}
                  </span>
                  {formModel.financialAidInfo && (
                    <StatusBadge
                      title={'\xa0' + formModel.financialAidInfo.title}
                      text={formModel.financialAidInfo.statement}
                      type={formModel.financialAidInfo.status}
                    />
                  )}
                </div>
              </div>
              <div className={classNames(styles.tableRowContent, styles.tableCell)}>
                <div className={classNames(checklistStyles.formChecklistDescription, styles.fieldRoot)}>
                  {renderChecklistFormField(formModel, props.complete, props.formUrlPrefix)}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ChecklistTable;
