import React from 'react';
import { FieldRenderProps } from 'react-final-form';
import { TextInput } from '@tcl-boron-prefabs/text-input';
import styles from './styles.module.css';
import { FormTableField } from './FormTable';
import FormError from './FormError';
import classNames from 'classnames';

type FormTextInputProps = FormTableField &
  FieldRenderProps<any> & {
    placeholder?: string;
    type?: string;
  };

const FormTextInput: React.FunctionComponent<FormTextInputProps> = ({
  input,
  meta,
  placeholder,
  type,
  readOnly,
  isError,
}) => {
  return (
    <div className={classNames(styles.fieldRoot, styles.tableRowContentFocusArea)}>
      <TextInput
        {...input}
        value={input.value ?? ''}
        type={type}
        disabled={meta.submitting || readOnly}
        classes={{
          root: isError ? '' : styles.inputRoot,
          inputWrapper: styles.inputWrapper,
          input: isError ? styles.textInputInputError : styles.inputInput,
        }}
        label={input.name}
        hideLabel
        placeholder={placeholder}
      />
      {isError ? <FormError error={meta.error} /> : undefined}
    </div>
  );
};

export default FormTextInput;
